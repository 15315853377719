import React, { memo } from 'react';
import PropTypes from 'prop-types';
import CheckBox from 'common/components/elements/inputs/checkbox';
import './style.scss'
import cx from 'classnames';

const CheckBoxLabel = ({
   label,
   className = '',
   disabled,
   onChange = () => {},
   name = 'default',
   checked = false,
   children,
   position = 'left',
   textColor = 'major dark:text-major-dark',
   padding = '3',
   margin = '3',
   textSize = 'sm',
   lineHeight = '18px',
   checkboxSize = '6',
   darkTextColor = 'major-dark',
   labelClassName = '',
   labelTextClassName = '',
   isSelectedState = false,
   labelTooltipText,
   tooltipText,
   checkboxIconTooltipText,
}) => {
   return (
      <label
         htmlFor={ `checkbox_${ name }` }
         className={ cx({
            'w-full h-full checkBox-label-content-v2 block select-none': true,
            [labelClassName]: labelClassName,
            'bg-hover dark:bg-hover-dark': isSelectedState && checked,
            '!cursor-default': disabled,
         }) }
         data-tooltip-content={ labelTooltipText ? labelTooltipText : null }
         data-tooltip-id={ labelTooltipText ? 'ams-top-tooltip' : null }
      >
         <div
            className={ cx({
               [`w-full select-none flex cursor-pointer p-${ padding } select-none ${ className }`]: true,
               '': position === 'left',
               'flex-row-reverse justify-between': position === 'right',
               '!cursor-default': disabled,
            }) }
            data-tooltip-id={ tooltipText ? 'ams-top-tooltip' : null }
            data-tooltip-content={ tooltipText ? tooltipText : null }
         >
            <div
               className={ cx({
                  [`pointer h-${ checkboxSize } w-${ checkboxSize } flex items-center justify-center`]: true,
                  [`mr-${ margin }`]: position === 'left',
                  [`ml-${ margin }`]: position === 'right',
               }) }
               data-tooltip-id={ checkboxIconTooltipText ? 'ams-top-tooltip' : null }
               data-tooltip-content={ checkboxIconTooltipText ? checkboxIconTooltipText : null }
            >
               <CheckBox
                  name={ name }
                  onChange={ onChange }
                  isChecked={ checked }
                  disabled={ disabled }
                  classNames='checkBox-label'
                  unCheckedIconColor='placeholder'
               />
            </div>
            {
               children && (children) }
            {
               label && !children && (
                  <div className={ `flex items-center checkBox-label-text` }>
                     <span
                        style={ {
                           lineHeight: lineHeight,
                        } }
                        className={ `leading-tight text-${ textSize } text-left checkBox-label-text block items-center text-${ textColor } dark:text-${ darkTextColor } ${ labelTextClassName }` }>{label}</span>
                  </div>
               )
            }
         </div>
      </label>
   );
};

CheckBoxLabel.propTypes = {
   label: PropTypes.string,
   children: PropTypes.any,
   className: PropTypes.string,
   disabled: PropTypes.bool,
   checked: PropTypes.bool,
   onChange: PropTypes.func,
   name: PropTypes.any,
   position: PropTypes.string,
   textColor: PropTypes.string,
   padding: PropTypes.string,
   margin: PropTypes.string,
   textSize: PropTypes.string,
   lineHeight: PropTypes.string,
   checkboxSize: PropTypes.string,
   darkTextColor: PropTypes.string,
   labelClassName: PropTypes.string,
   labelTextClassName: PropTypes.string,
   isSelectedState: PropTypes.bool,
   labelTooltipText: PropTypes.string,
   tooltipText: PropTypes.string,
   checkboxIconTooltipText: PropTypes.string,
};

export default memo(CheckBoxLabel);
